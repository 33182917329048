/* eslint-disable no-console */
import React, { useMemo } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import Modal from '../../Modals/Modal';
import ModalHeader from '../../Modals/ModalHeader';
import ModalMain from '../../Modals/ModalMain';
import ModalFooter from '../../Modals/ModalFooter';
import { BbButton, BBSelect } from '../../@browsbox-ui';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import i18n from '../../../internationalization/i18n';
import CircleLoader from '../../Loader/CircleLoader';
import { refreshBaseModuleList } from '../../../actions/baseModuleItems';

const useImportMutation = () => useMutation(
  'base_module_import',
  ({
    moduleId,
    file,
    language,
  }) => {
    const data = new FormData();
    data.append('file', file);
    data.append('language', language);

    return axios.post(`/api/base-module/items/import/${moduleId}`, data);
  },
);

const ImportItemsModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const defaultLanguageCode = useSelector(state => state.baseModuleItems?.filters?.language?.code);
  const languages = useSelector(state => state.baseModule.langs);
  const currentModuleId = useSelector(state => state.baseModule.currentModule.id);

  const languageOptions = useMemo(() => languages.map(item => ({
    id: item.code,
    title: item.code.toUpperCase(),
  })), [languages]);

  const [file, setFile] = React.useState(null);
  const [language, setLanguage] = React.useState(languageOptions.find(item => item.id === defaultLanguageCode));
  const inputRef = React.useRef(null);

  const mutation = useImportMutation();

  const handleFileChange = (event) => {
    event.persist();
    setFile(event.target.files[0]);
  };

  const handleImport = async () => {
    await mutation.mutateAsync({
      moduleId: currentModuleId,
      language: language.id,
      file,
    });

    dispatch(refreshBaseModuleList());
  };

  let error = null;

  if (mutation.error) {
    if (mutation.error?.response?.status === 400) {
      error = [
        mutation.error.response.data.message,
        mutation.error.response.data?.errors?.children?.file?.errors?.join('\n'),
      ].join(': \n');
    } else {
      error = 'Unexpected error occurred.';
    }
  }

  return (
    <Modal onClose={onClose} closeOnBackdropClick={false}>
      <ModalHeader
        title={i18n.t('BASE_MODULE.importData')}
        onClose={onClose}
      />
      <ModalMain>
        <div className="o-bb-modal__content" style={{ minHeight: '30vh' }}>
          {mutation.isLoading && (
            <div>
              <CircleLoader />
            </div>
          )}
          {mutation.isSuccess && (
            <div style={{ textAlign: 'center' }}>
              <h4>
                {i18n.t('BASE_MODULE.dataImportedSuccessfully')}
              </h4>
              <br />
              <BbButton onClick={onClose}>
                {i18n.t('CONTENT.close').toUpperCase()}
              </BbButton>
            </div>
          )}
          {mutation.isError && (
            <div style={{ textAlign: 'center' }}>
              <h4 className="c-bb-form__input-error">
                {error}
              </h4>
              <br />
              <BbButton onClick={mutation.reset}>
                {i18n.t('BASE_MODULE.retry').toUpperCase()}
              </BbButton>
            </div>
          )}
          {mutation.isIdle && (
            <>
              <div style={{ marginBottom: '24px' }}>
                <BBSelect
                  placeholder={i18n.t('BASE_MODULE.language')}
                  value={language}
                  onChange={setLanguage}
                  items={languageOptions}
                />
              </div>
              <div>
                <BbButton icon="file" onClick={() => inputRef.current?.click()}>
                  {file ? (
                    <span>{file.name} ({file.size} bytes)</span>
                  ) : i18n.t('BASE_MODULE.selectFile').toUpperCase()}
                </BbButton>
                <input
                  ref={inputRef}
                  onChange={handleFileChange}
                  style={{ visibility: 'hidden' }}
                  type="file"
                  multiple={false}
                  accept="text/csv"
                />
              </div>
              <div style={{ marginTop: '24px', textAlign: 'right' }}>
                <BbButton icon="download" onClick={() => window.open(`/api/base-module/items/import/sample-file/${currentModuleId}`)}>
                  {i18n.t('BASE_MODULE.downloadSampleFile').toUpperCase()}
                </BbButton>
              </div>
            </>
          )}
        </div>
      </ModalMain>
      {mutation.isIdle && (
        <ModalFooter>
          <BbButton
            disabled={!(file && language)}
            primary
            onClick={handleImport}
          >
            <FontAwesomeIcon name="fak fa-plus" className="c-bb-button__icon" />
            {i18n.t('BASE_MODULE.import').toUpperCase()}
          </BbButton>
          <BbButton
            onClick={onClose}
          >
            {i18n.t('CONTENT.cancel').toUpperCase()}
          </BbButton>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ImportItemsModal;
