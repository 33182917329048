import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

const defaultProps = {
  children: null,
  align: null,
};

const ModalFooter = props => (
  <footer
    className={classNames(
      'o-bb-modal__footer',
      props.align && `o-bb-modal__footer--align-${props.align}`,
      'o-bb-modal__footer--actions',
    )}
  >
    { props.children }
  </footer>
);

ModalFooter.propTypes = propTypes;
ModalFooter.defaultProps = defaultProps;

export default ModalFooter;
