import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { BbButtonGroup, BbButton } from '../../@browsbox-ui';
import {
  setBaseModuleListFilters,
  reorderBaseModuleItems,
} from '../../../actions/baseModuleItems';
import BaseModuleItemsList from './BaseModuleItemsList';
import i18n from '../../../internationalization/i18n';
import BaseModuleItemsFilters from './BaseModuleItemsFilters';
import { getModuleByName, selectBaseModuleItemsList } from '../../../selectors';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import ImportItemsModal from './ImportItemsModal';

const propTypes = {
  itemsList: PropTypes.array.isRequired,
  setBaseModuleListFilters: PropTypes.func.isRequired,
  reorderBaseModuleItems: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentModule: PropTypes.any.isRequired,
  filters: PropTypes.object.isRequired,
  isImportEnabled: PropTypes.bool.isRequired,
};

const defaultProps = {
  itemListFilterByCategory: null,
  currentModuleItem: null,
};

const BaseModuleItems = (props) => {
  const {
    isLoading, itemsList, currentModule, isImportEnabled,
  } = props;
  const history = useHistory();
  const { name } = useParams();
  const [importing, setImporting] = React.useState(false);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // TODO:: How to sort items on multi-language mode?
    props.reorderBaseModuleItems({ oldIndex, newIndex });
  };

  const handleCreateModuleItem = React.useCallback(
    () => history.push(`/item/create/${name}`),
    [history, name],
  );

  const handleItemsImport = () => {
    setImporting(true);
  };

  const handleFiltersChange = React.useCallback((filters) => {
    props.setBaseModuleListFilters(filters);
  }, [props.setBaseModuleListFilters]);

  if (!currentModule) {
    return null;
  }

  return (
    <div className="c-bb-base-module">
      <div className="c-bb-base-module__header">
        <FontAwesomeIcon name={currentModule.icon || 'fak fa-module'} className="c-bb-base-module__icon" />
        <h1 className="c-bb-base-module__title">
          {props.currentModule && props.currentModule.label}
        </h1>
        <BbButtonGroup>
          <BbButton
            primary
            onClick={handleCreateModuleItem}
          >
            <FontAwesomeIcon name="fak fa-plus" className="c-bb-button__icon" />
            {i18n.t('CONTENT.create').toUpperCase()}
          </BbButton>
          {isImportEnabled && (
            <BbButton
              primary
              onClick={handleItemsImport}
            >
              <FontAwesomeIcon name="fak fa-plus" className="c-bb-button__icon" />
              {i18n.t('BASE_MODULE.importData').toUpperCase()}
            </BbButton>
          )}
        </BbButtonGroup>
      </div>
      <div className="c-bb-base-module-items-container">
        <BaseModuleItemsFilters
          initialFilters={props.filters}
          onChange={handleFiltersChange}
        />
        <BaseModuleItemsList
          items={itemsList}
          isLoading={isLoading}
          onSortEnd={onSortEnd}
          sortingEnabled={props.currentModule.sorting_enabled}
          currentModule={props.currentModule}
        />
      </div>
      {importing && (
        <ImportItemsModal
          onClose={() => setImporting(false)}
        />
      )}
    </div>
  );
};

BaseModuleItems.propTypes = propTypes;
BaseModuleItems.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const currentModule = getModuleByName(state, props.match.params.name);

  return {
    key: currentModule ? currentModule.id : undefined,
    itemsList: selectBaseModuleItemsList(state),
    currentModule,
    isLoading: state.baseModuleItems.fetchingItemsList,
    filters: state.baseModuleItems.filters,
    isImportEnabled: state.global.websiteSettings?.enabledModules?.includes('base_module_items_import') ?? false,
  };
};

const mapDispatchToProps = {
  reorderBaseModuleItems,
  setBaseModuleListFilters,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BaseModuleItems));
